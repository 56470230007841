import { get, post } from '@/api/index';
export const uploadPublic = params => post(`/api/uploader/v1/upload_image`, params);//直播
export const logout = params => get(`/api/login/v1/foreuser/outlogin`, params); //退出

export const toFaculty = params => get(`/api/login/v1/foreuser/searchcode`, params); // 学校院系
export const toTeacher = params => get(`/api/login/v1/foreuser/facultyteacher`, params); // 院系下老师列表接口

export const toCode = params => post(`/api/login/v1/foreuser/sendsmscode`, params); // 忘记密码发送短信验证
export const toPassword = params => post(`/api/login/v1/foreuser/changeforgetpassword`, params); // 忘记密码修改密码

export const logincheckphone = params => post(`/api/login/v1/foreuser/logincheckphone`, params);

<template>
  <div style="height: 100%">
    <Header :bgColor="bgColor"></Header>
    <div class="courseinfoWrap">
      <p class="sq50">
        <i class="iconfont icon-fanhui" @click="go"></i> 通知 > 通知详情
      </p>
      <div class="content">
        <div
          style="
            width: 1000px;
            margin: 0 auto;
            height: 98%;
            overflow-y: auto;
            position: relative;
          "
        >
          <h1>{{ formLabelAlign.title }}</h1>
          <div class="fujian">
            <i class="iconfont icon-kecheng"></i>课程：{{
              formLabelAlign.course[0].title
            }}
            <i class="iconfont icon-jiaoshizigezheng"></i
            >{{ formLabelAlign.add_name }} <i class="iconfont icon-history"></i
            >{{ formLabelAlign.make_time }}
          </div>
          <p>
            {{ formLabelAlign.content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@p/video/components/header";
import { notinfo } from "../assets/api";

export default {
  name: "noticeDetail",
  components: {
    Header,
  },
  data() {
    return {
      bgColor: true,
      formLabelAlign: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    go() {
      this.$router.go(-1);
    },
    getDetail() {
      const params = {
        notification_id: this.$route.query.notification_id,
        course_id: this.$route.query.id,
        teach_class_id: window.sessionStorage.getItem("teaching_class_id"),
      };
      notinfo(params).then((res) => {
        if (res.code == 0) {
          this.formLabelAlign = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.courseinfoWrap {
    position:absolute;
    left:0px;
  top:73px;
    width:100%;
    height:calc(100% - 110px);
    // margin-top: 20px;
    background: #fff;
    box-sizing: border-box;
    overflow-y: auto;
  padding: 0 20px 0;
  .sq50 {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
  .content {
    width: 100%;
    padding-top: 37px;
    box-sizing: border-box;
    h1 {
      text-align: center;
      font-size: 20px;
    }
    .fujian {
      text-align: center;
      font-size: 13px;
      padding: 20px 0;
      i {
        margin-left: 20px;
        margin-right: 5px;
        color: #666;
        font-size: 13px;
      }
    }
    p {
      text-indent: 2em;
      line-height: 2;
      font-size: 14px;
      padding-bottom: 28px;
    }
  }
}
</style>
